
#contact form {
  max-width: 35em;
  margin-left: auto;
  margin-right: auto;
}

#contact label,
#contact button {
  display: block;
  margin-top: 1em;
  text-align: center;
  background-color: rgba(var(--primary-dark), .5);
  color: white;
  transition: color .5s, background-color .5s;
}

#contact button {
  border: 1px solid rgba(var(--primary-dark), .8);
  width: 100%;
}

#contact button:hover {
  background-color: rgba(var(--primary-dark), .83);
}

#contact button img {
  border: 0 none;
  height: .8em;
  margin-bottom: .2em;
}

#contact input,
#contact textarea {
  width: 100%;
  border: 0 none;
  padding: .5em;
  color: rgb(var(--primary-dark));
  transition: border .5s, background-color .5s, color .5s;
}

#contact input {
  text-align: center;
}

#contact textarea {
  height: 11em;
}

#contact .overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: rgba(var(--primary-dark), .93);
  text-align: center;
}

#contact .overlay > div {
  background-color: rgb(var(--grey-2));
  position: relative;
  margin-top: 22%;
  margin-left: auto;
  margin-right: auto;
  min-height: 200px;
  max-height: 70%;
  min-width: 200px;
  max-width: 70%;
  text-align: center;
}

#contact .overlay .close {
  width: 1em;
  height: 1em;
  position: absolute;
  top: 0;
  right: 6px;
  font-size: 1em;
}

#contact .overlay .result {
  position: absolute;
  font-size: 2em;
  margin-top: 3em;
  right: 0;
  left: 0;
}
