.page-footer {
  align-items: center;
  background-color: rgba(var(--primary-dark), .93);
  border-top: 1px dashed rgb(var(--grey-2));
  color: rgb(var(--primary-light));
  display: flex;
  flex-direction: column;
  font-family: 'Arvo', serif;
  padding: 2em;

  .niztech-logo {
    height: 2em;
    width: 2em;
    margin: 1rem;
  }
}
