:root {
  --primary-dark: 35, 27, 36;
  --secondary-dark: 44, 77, 148;
  --primary-light: 247, 247, 237;
  --grey-0: 140, 140, 140;
  --grey-1: 185, 185, 185;
  --grey-2: 249, 249, 249;
  --grey-3: 255, 255, 255;
}

body {
  background-image: url("blur.87cd3389.jpg");
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  background-attachment: fixed;
}

html {
  color: #222;
  font-size: 1em;
  line-height: 1.4;
}

::selection {
  text-shadow: none;
  background: #b3d4fc;
}

hr {
  border: 0;
  border-top: 1px solid #ccc;
  height: 1px;
  margin: 1em 0;
  padding: 0;
  display: block;
}

audio, canvas, iframe, img, svg, video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

ul {
  margin: 0;
  padding: 0;
}

html, body, div, span, textarea, input, label {
  box-sizing: border-box;
}

@keyframes pop {
  0% {
    width: 10em;
    height: 10em;
  }

  93% {
    width: 16em;
    height: 16em;
  }

  100% {
    width: 15em;
    height: 15em;
  }
}

.shadow {
  box-shadow: 3px 3px 15px -6px #000;
}

.visuallyhidden {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus {
  clip: auto;
  width: auto;
  height: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

.readme {
  background-color: #fff;
  border: 1px solid #e3e3e3;
  width: 80%;
  margin: 4em auto;
  padding: 2em;
}

.readme h1, .readme h2 {
  border-bottom: 1px solid #a3a3a3;
  line-height: 2em;
}

.readme p {
  line-height: 1.6em;
}

.readme li {
  margin-bottom: 1.3em;
}

.readme code {
  background-color: #ebebeb;
  border-radius: 4px;
  margin-top: 1em;
  padding: .2em;
  font-family: monospace;
  display: inline-block;
}

.readme .function {
  color: #b23f3f;
}

.readme .parameter {
  color: #028181;
}

.readme .variable {
  color: #b497bd;
}

.readme .string {
  color: #de1849;
}

.button.visit {
  background-color: rgba(var(--primary-dark), .1);
  border: 1px solid rgba(var(--primary-dark), .8);
  box-shadow: 0 3px 5px rgba(var(--grey-0), .5);
  color: rgb(var(--primary-dark));
  border-radius: 4px;
  padding: .1em 1em;
  font-family: Arvo, serif;
  font-size: .9em;
  text-decoration: none;
  transition: color .5s, background-color .5s;
}

.button.visit img {
  width: .9em;
  height: .9em;
  margin-top: -.3em;
}

.button.visit:hover {
  background-color: rgb(var(--grey-2));
}

a {
  color: rgb(var(--secondary-dark));
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.calvin-hobbes-cart {
  animation-name: rock;
  animation-duration: 1.75s;
  animation-iteration-count: infinite;
  position: relative;
}

@keyframes rock {
  0% {
    transform: rotate(1deg);
  }

  5% {
    bottom: 3px;
  }

  10% {
    transform: rotate(0);
  }

  15% {
    bottom: 0;
  }

  20% {
    transform: rotate(2deg);
  }

  25% {
    bottom: 2px;
  }

  30% {
    transform: rotate(-1deg);
  }

  35% {
    bottom: -1px;
  }

  40% {
    transform: rotate(1deg);
  }

  45% {
    bottom: 2px;
  }

  50% {
    transform: rotate(1deg);
  }

  55% {
    bottom: 0;
  }

  60% {
    transform: rotate(2deg);
  }

  65% {
    bottom: 3px;
  }

  70% {
    transform: rotate(1deg);
  }

  75% {
    bottom: 0;
  }

  80% {
    transform: rotate(0);
  }

  85% {
    bottom: -2px;
  }

  90% {
    transform: rotate(-1deg);
  }

  95% {
    bottom: 3px;
  }
}

.circle {
  color: rgb(var(--primary-dark));
  text-align: center;
  background-repeat: no-repeat;
  background-size: 100px 100px;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  font-family: Arvo;
  font-size: 2em;
  font-style: italic;
  font-weight: 700;
  line-height: 3.2em;
}

.circle.light {
  background-image: url("Circle_light.37644ba6.svg");
}

.circle.dark {
  background-image: url("Circle_dark.78902df1.svg");
}

.drop-cap {
  float: left;
  text-align: center;
  width: auto;
  height: 1em;
  margin-left: -.1em;
  padding-top: .3em;
  padding-right: .1em;
  font-size: 4em;
  font-weight: 700;
  display: inline-block;
  font-family: Arvo, serif !important;
}

.error {
  background-color: #ffc4c4 !important;
  border: 1px solid #fc6e6e !important;
}

.img-circle {
  border-radius: 50%;
  display: inline-block;
  overflow: hidden;
}

.img-circle img {
  width: inherit;
  height: inherit;
}

.img-div {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.niztech-logo {
  background-image: url("Logo.12ada107.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 3em;
  height: 3em;
  position: relative;
}

#wrapper {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  width: 80%;
  margin: 4rem auto;
  overflow: hidden;
}

@media only screen and (width <= 710px) {
  #wrapper {
    width: unset;
    margin: .5rem;
  }
}

.page-footer {
  background-color: rgba(var(--primary-dark), .93);
  border-top: 1px dashed rgb(var(--grey-2));
  color: rgb(var(--primary-light));
  flex-direction: column;
  align-items: center;
  padding: 2em;
  font-family: Arvo, serif;
  display: flex;
}

.page-footer .niztech-logo {
  width: 2em;
  height: 2em;
  margin: 1rem;
}

.page-header {
  background-color: rgba(var(--primary-dark), .93);
  color: #fff;
}

.page-header .mast {
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  font-family: Arvo, serif;
  font-size: 2.75rem;
  font-weight: 700;
}

.page-header .mast span {
  border-bottom: 5px dotted rgba(var(--grey-2), .6);
}

.page-header .tagline {
  text-align: center;
  margin: 1rem 0 0;
  padding: 0;
  font-family: Arbutus Slab, serif;
  font-size: 1rem;
}

.page-header .niztech-logo {
  position: relative;
  top: 3rem;
  left: 3rem;
}

.section {
  padding: 4.5em 6em;
  font-family: Arbutus Slab, serif;
  font-size: 12pt;
  line-height: 2em;
}

.section em {
  border-radius: 3px;
  padding-left: .4em;
  padding-right: .4em;
  font-style: normal;
}

.section hr {
  width: 40%;
  margin: 2em auto;
}

.section.dark {
  background-color: rgba(var(--primary-dark, .93));
  color: rgb(var(--grey-2));
}

.section.dark hr {
  border-top: 1px dashed rgb(var(--grey-2));
}

.section.dark em {
  background-color: rgba(var(--grey-3), .3);
}

.section.light {
  background-color: rgba(var(--primary-light), .87);
  color: rgb(var(--primary-dark));
}

.section.light em {
  background-color: rgba(var(--primary-dark), .1);
}

.section.light hr {
  border-top: 1px dashed var(--primary-dark);
}

.section h2 {
  text-align: center;
  padding-bottom: .5rem;
  font-size: 3.125rem;
}

@media only screen and (width <= 710px) {
  .section {
    padding: 1rem 2rem;
  }

  .section h2 {
    padding-bottom: .35rem;
  }
}

.sub-select {
  padding: 0;
  list-style: none;
  display: flex;
}

.sub-select li {
  vertical-align: top;
}

@media only screen and (width <= 710px) {
  .social-section .sub-select {
    flex-direction: column;
  }

  .social-section .sub-select li {
    margin-top: .4rem;
    margin-bottom: .4rem;
  }
}

#contact form {
  max-width: 35em;
  margin-left: auto;
  margin-right: auto;
}

#contact label, #contact button {
  text-align: center;
  background-color: rgba(var(--primary-dark), .5);
  color: #fff;
  margin-top: 1em;
  transition: color .5s, background-color .5s;
  display: block;
}

#contact button {
  border: 1px solid rgba(var(--primary-dark), .8);
  width: 100%;
}

#contact button:hover {
  background-color: rgba(var(--primary-dark), .83);
}

#contact button img {
  border: 0;
  height: .8em;
  margin-bottom: .2em;
}

#contact input, #contact textarea {
  color: rgb(var(--primary-dark));
  border: 0;
  width: 100%;
  padding: .5em;
  transition: border .5s, background-color .5s, color .5s;
}

#contact input {
  text-align: center;
}

#contact textarea {
  height: 11em;
}

#contact .overlay {
  z-index: 1000;
  background-color: rgba(var(--primary-dark), .93);
  text-align: center;
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
}

#contact .overlay > div {
  background-color: rgb(var(--grey-2));
  text-align: center;
  min-width: 200px;
  max-width: 70%;
  min-height: 200px;
  max-height: 70%;
  margin-top: 22%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

#contact .overlay .close {
  width: 1em;
  height: 1em;
  font-size: 1em;
  position: absolute;
  top: 0;
  right: 6px;
}

#contact .overlay .result {
  margin-top: 3em;
  font-size: 2em;
  position: absolute;
  left: 0;
  right: 0;
}

#about_me {
  background-color: rgba(var(--primary-dark), .93);
}

#about_me .my-ugly-mug {
  text-align: center;
  margin: 0 auto 3em;
}

#about_me .my-ugly-mug .img-circle {
  animation-name: pop;
  animation-duration: .5s;
}

.social-section .sub-select {
  margin: 0;
  padding: 0;
}

.social-section .sub-select li {
  width: 32%;
  margin: 0;
  padding: 0;
}

.social-section .sub-select .circle {
  background-size: 4.5em;
  width: 4.5em;
  height: 4.5em;
}

.social-section img.linkedin {
  width: 3em;
  margin-top: 1.2em;
}

.social-section img.github {
  width: 3em;
  margin-top: 1em;
}

.social-section img.resume {
  width: 3em;
  margin-top: .75em;
}

@media only screen and (width <= 710px) {
  .social-section .sub-select {
    justify-self: center;
  }

  .social-section .sub-select li {
    margin-top: .4rem;
    margin-bottom: .4rem;
  }
}

.section-tags ul {
  text-align: center;
  margin: 0;
  padding: 0;
}

.section-tags li {
  color: rgba(var(--grey-3), .5);
  background-color: rgba(var(--grey-3), .1);
  border-radius: 3px;
  margin: .2em;
  padding-left: .4em;
  padding-right: .4em;
  font-style: normal;
  list-style-type: none;
  display: inline-block;
}

.section-tags.light li {
  color: var(--primary-dark);
  background-color: rgba(var(--primary-dark), .1);
}

#yellowbrickroad .funfacts p {
  display: inline;
}

#work .sub-select {
  cursor: pointer;
  flex-direction: row;
  display: flex;
}

#work .sub-select li {
  border: 1px solid rgb(var(--grey-1));
  box-shadow: 0 3px 5px rgba(var(--grey-0), .5);
  border-radius: 10px;
  grid-template-areas: "icon"
                       "title"
                       "description";
  margin: 0 1rem;
  padding: 1rem;
  transition: background-color .5s;
  display: grid;
}

#work .sub-select li:hover, #work .sub-select li.active {
  background-color: rgba(var(--grey-2), .3);
}

#work .sub-select .title {
  border-bottom: 1px solid var(--primary-dark);
  text-transform: uppercase;
  grid-area: title;
  justify-self: center;
  font-family: Arvo, serif;
}

#work .sub-select .icon {
  grid-area: icon;
}

#work .sub-select .desc {
  text-align: center;
  grid-area: description;
}

#work #work img.bit {
  width: 65px;
  height: 65px;
}

#work .examples figure {
  float: left;
  margin-bottom: auto;
  margin-left: 0;
  display: inline-block;
}

#work .examples .title {
  text-transform: uppercase;
  font-family: Arvo, serif;
  font-weight: 700;
}

#work .examples .logo {
  width: 8em;
}

#work .before-after {
  vertical-align: middle;
  flex-direction: row;
  display: flex;
}

#work .before-after ul {
  margin: 0;
  padding: 0;
  list-style: inside;
}

#work .before-after .title {
  font-size: .8em;
  font-weight: bolder;
}

#work .before-after-arrow {
  background-image: url("Chevron_right.92b3426c.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  align-self: center;
  width: 3rem;
  height: 3rem;
  margin: 1rem;
  display: block;
}

#work .before, #work .after {
  background-color: rgb(var(--grey-1));
  height: inherit;
  border-radius: 8px;
  flex: 2;
  padding: 1em;
}

#work .examples iframe {
  border: 0;
  width: 100%;
  height: 20em;
  overflow: hidden;
}

@media only screen and (width <= 1200px) {
  #work .sub-select {
    flex-direction: column;
  }

  #work .sub-select li {
    grid-template-columns: fit-content(110px) 1fr;
    grid-template-areas: "icon title"
                         "icon description";
    margin: 1rem 0;
  }

  #work .sub-select li:first-child {
    margin-top: 0;
  }

  #work .sub-select li:last-child {
    margin-bottom: 0;
  }

  #work .sub-select .icon {
    align-self: center;
    margin-right: 1rem;
  }

  #work .sub-select .title {
    justify-self: left;
  }

  #work .sub-select .desc {
    text-align: left;
  }

  #work .before-after {
    flex-direction: column;
  }

  #work .before-after-arrow {
    transform: rotate(90deg);
  }
}
/*# sourceMappingURL=index.5b584b7d.css.map */
