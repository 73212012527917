@keyframes pop {
  0% {
    width: 10em;
    height: 10em;
  }
  93% {
    width: 16em;
    height: 16em;
  }
  100% {
    width: 15em;
    height: 15em;
  }
}
