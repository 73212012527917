.img-circle {
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;

  img {
    width: inherit;
    height: inherit;
  }
}
