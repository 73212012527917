.section-tags {
  ul {
    text-align: center;
    margin: 0;
    padding: 0;
  }

  li {
    color: rgba(var(--grey-3), .5);
    display: inline-block;
    list-style-type: none;
    font-style: normal;
    padding-left: .4em;
    padding-right: .4em;
    margin: .2em;
    border-radius: 3px;
    background-color: rgba(var(--grey-3), .1);
  }
}

.section-tags.light {
  li {
    color: var(--primary-dark);
    background-color: rgba(var(--primary-dark), .1);
  }
}
