.calvin-hobbes-cart {
  animation-name: rock;
  position: relative;
  animation-duration: 1.75s;
  animation-iteration-count: infinite;
}

@keyframes rock {
  0% {
    transform: rotate(1deg);
  }
  5% {
    bottom: 3px;
  }
  10% {
    transform: rotate(0deg);
  }
  15% {
    bottom: 0;
  }
  20% {
    transform: rotate(2deg);
  }
  25% {
    bottom: 2px;
  }
  30% {
    transform: rotate(-1deg);
  }
  35% {
    bottom: -1px;
  }
  40% {
    transform: rotate(1deg);
  }
  45% {
    bottom: 2px;
  }
  50% {
    transform: rotate(1deg);
  }
  55% {
    bottom: 0;
  }
  60% {
    transform: rotate(2deg);
  }
  65% {
    bottom: 3px;
  }
  70% {
    transform: rotate(1deg);
  }
  75% {
    bottom: 0;
  }
  80% {
    transform: rotate(0deg);
  }
  85% {
    bottom: -2px;
  }
  90% {
    transform: rotate(-1deg);
  }
  95% {
    bottom: 3px;
  }
}
