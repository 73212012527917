#about_me {
  background-color: rgba(var(--primary-dark), .93);

  .my-ugly-mug {
    margin: 0 auto 3em auto;
    text-align: center;

    .img-circle {
      animation-name: pop;
      animation-duration: .5s;
    }
  }
}
