.section {
  font-family: 'Arbutus Slab', serif;
  font-size: 12pt;
  line-height: 2em;
  padding: 4.5em 6em 4.5em 6em;

  em {
    border-radius: 3px;
    font-style: normal;
    padding-left: .4em;
    padding-right: .4em;
  }

  hr {
    margin: 2em auto 2em auto;
    width: 40%;
  }

  &.dark {
    background-color: rgba(var(--primary-dark, .93));
    color: rgb(var(--grey-2));
  }

  &.dark hr {
    border-top: 1px dashed rgb(var(--grey-2));
  }

  &.dark em {
    background-color: rgba(var(--grey-3), .3);
  }

  &.light {
    background-color: rgba(var(--primary-light), .87);
    color: rgb(var(--primary-dark));
  }

  &.light em {
    background-color: rgba(var(--primary-dark), .1);
  }

  &.light hr {
    border-top: 1px dashed var(--primary-dark);
  }

  h2 {
    font-size: 3.125rem;
    padding-bottom: .5rem;
    text-align: center;
  }
}

@media only screen and (max-width: 710px) {
  .section {
    padding: 1rem 2rem;

    h2 {
      padding-bottom: .35rem;
    }
  }
}
