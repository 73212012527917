.page-header {
  background-color: rgba(var(--primary-dark), .93);
  color: white;

  .mast {
    font-family: 'Arvo', serif;
    font-size: 2.75rem;
    font-weight: 700;
    margin: 0;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
    span {
      border-bottom: 5px dotted rgba(var(--grey-2), .6);
    }
  }

  .tagline {
    font-family: 'Arbutus Slab', serif;
    font-size: 1rem;
    margin: 1rem 0 0 0;
    padding: 0;
    text-align: center;
  }

  .niztech-logo {
    left: 3rem;
    position: relative;
    top: 3rem;
  }
}
