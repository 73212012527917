#wrapper {
  margin: 4rem auto;
  width: 80%;

  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  overflow: hidden;
}

@media only screen and (max-width: 710px) {
  #wrapper {
    margin: 0.5rem;
    width: unset;
  }
}
