.social-section {
  .sub-select {
    margin: 0;
    padding: 0;
  }

  .sub-select li {
    width: 32%;
    margin: 0;
    padding: 0;

  }

  .sub-select .circle {
    width: 4.5em;
    height: 4.5em;
    background-size: 4.5em;
  }

  img.linkedin {
    width: 3em; /*291, 79*/
    margin-top: 1.2em;
  }

  img.github {
    width: 3em; /*291, 79*/
    margin-top: 1em;
  }

  img.resume {
    width: 3em; /*291, 79*/
    margin-top: .75em;
  }
}

@media only screen and (max-width: 710px) {
  .social-section {
    .sub-select {
      justify-self: center;
    }

    .sub-select li {
      margin-top: 0.4rem;
      margin-bottom: 0.4rem;
    }
  }
}
