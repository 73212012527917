/*
font-family: 'Arvo', serif; /*BLOCK
font-family: 'Arbutus Slab', serif; /*PARAGRAPH


Color:
Light Color: #f9f9f9 -- rgba(var(--primary-light), .87);
Purplish Color: #231B23 -- rgba(35,27,36, .93);
*/

:root {
  --primary-dark: 35, 27, 36;
  --secondary-dark: 44, 77, 148;

  --primary-light: 247, 247, 237;

  --grey-0: 140, 140, 140;
  --grey-1: 185, 185, 185;
  --grey-2: 249, 249, 249; /* #f9f9f9 */
  --grey-3: 255, 255, 255; /* #ffffff */
}

body {
  background-image: url('../img/blur.jpg');
  background-size: cover;
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center;
}
