.sub-select {
  list-style: none;
  padding: 0;
  display: flex;

  li {
    vertical-align: top;
  }
}

@media only screen and (max-width: 710px) {
  .social-section {
    .sub-select {
      flex-direction: column;
    }

    .sub-select li {
      margin-top: 0.4rem;
      margin-bottom: 0.4rem;
    }
  }
}
