#work {
  .sub-select {
    cursor: pointer;
    display: flex;
    flex-direction: row;

    li {
      border-radius: 10px;
      border: 1px solid rgb(var(--grey-1));
      box-shadow: 0 3px 5px rgba(var(--grey-0), 0.5);
      display: grid;
      grid-template-areas: 'icon'
      'title'
      'description';
      margin: 0 1rem;
      padding: 1rem;
      transition: background-color .5s;

      &:hover, &.active {
        background-color: rgba(var(--grey-2), .3);
      }
    }

    .title {
      border-bottom: 1px solid var(--primary-dark);
      font-family: 'Arvo', serif;
      grid-area: title;
      justify-self: center;
      text-transform: uppercase;
    }

    .icon {
      grid-area: icon;
    }

    .desc {
      text-align: center;
      grid-area: description;
    }
  }


  #work img.bit {
    width: 65px;
    height: 65px;
  }

  .examples figure {
    float: left;
    margin-left: 0;
    display: inline-block;
    margin-bottom: auto;
  }

  .examples .title {
    text-transform: uppercase;
    font-family: 'Arvo', serif;
    font-weight: 700;
  }

  .examples .logo {
    width: 8em;
  }

  .before-after {
    display: flex;
    flex-direction: row;
    vertical-align: middle;

    & ul {
      list-style: inside;
      margin: 0;
      padding: 0;
    }

    & .title {
      font-size: .8em;
      font-weight: bolder;
    }
  }

  .before-after-arrow {
    align-self: center;
    background-image: url('../img/Chevron_right.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    height: 3rem;
    margin: 1rem;
    width: 3rem;
  }

  .before, .after {
    -moz-box-flex: 2;
    -ms-flex: 2;
    -webkit-box-flex: 2;
    -webkit-flex: 2;
    background-color: rgb(var(--grey-1));
    border-radius: 8px;
    flex: 2;
    height: inherit;
    padding: 1em;
  }

  .examples iframe {
    border: 0 none;
    height: 20em;
    overflow: hidden;
    width: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  #work {
    .sub-select {
      flex-direction: column;

      li {
        margin: 1rem 0;
        grid-template-areas: 'icon title'
          'icon description';
        grid-template-columns: fit-content(110px) 1fr;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .icon {
        align-self: center;
        margin-right: 1rem;
      }

      .title {
        justify-self: left;
      }

      .desc {
        text-align: left;
      }
    }

    .before-after {
      flex-direction: column;
    }

    .before-after-arrow {
      transform: rotate(90deg);
    }
  }
}
