
.drop-cap {
  display: inline-block;
  width: auto;
  height: 1em;
  float: left;
  padding-top: .3em;
  padding-right: .1em;
  margin-left: -.1em;
  font-weight: 700;
  font-family: 'Arvo', serif !important;
  font-size: 4em;
  text-align: center;
}
