.readme {
  width: 80%;
  background-color: #ffffff;
  padding: 2em;
  border: 1px solid #e3e3e3;
  margin-top: 4em;
  margin-bottom: 4em;
  margin-left: auto;
  margin-right: auto;

  & h1,
  & h2 {
    border-bottom: 1px solid #a3a3a3;
    line-height: 2em;
  }

  & p {
    line-height: 1.6em;
  }

  & li {
    margin-bottom: 1.3em;
  }

  & code {
    background-color: #ebebeb;
    font-family: monospace;
    border-radius: 4px;
    padding: .2em;
    margin-top: 1em;
    display: inline-block;
  }

  & .function {
    color: #B23F3F;
  }

  & .parameter {
    color: #028181;
  }

  & .variable {
    color: #B497BD;
  }

  & .string {
    color: #DE1849;
  }
}
